import React, { useMemo } from 'react';
import { graphql, useStaticQuery } from 'gatsby';

export const useAuthor = (authorId: Number) => {
  const { allStrapiAuthor } = useStaticQuery(graphql`
    query AuthorQuery($id: Int) {
    allStrapiAuthor(filter: {strapiId: {eq: $id}}) {
      edges {
        node {
          id
          email
          firstName
          lastName
          description
          jobTitle
          linkedin
          avatar {
            publicURL
          }
          projects {
            passwordProtection
            status
            title
            id
            project_detail
          }
          strapiId
        }
      }
    }
  }
  `);

  const match = useMemo(() => (
    allStrapiAuthor.edges.find(({ node }: any) => node.strapiId === authorId)
  ), [ allStrapiAuthor.edges, authorId ])

  return match.node;
}

export default useAuthor;
