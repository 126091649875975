import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

export const useAuthorId = () => {
  const { site } = useStaticQuery(
    graphql`
      query SiteMetaData {
        site {
          siteMetadata {
            authorId
          }
        }
      }
    `
  )
  return parseInt(site.siteMetadata.authorId);
}
export default useAuthorId;
