import React, { useContext, useEffect } from 'react';

import SEO from "../layout/seo"
import { useAuthorId } from "../queries/useAuthorId";
import { useAuthor } from "../queries/useAuthor";
import { Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { StateContext } from '../state/StateProvider';
import { ActionType } from '../state/types';
import { motion } from 'framer-motion';
import HomePassword from '../components/home/password/HomePassword';
import Home from '../components/home/default/Home';

const useStyles = makeStyles(() => ({

}));

const IndexPage = () => {
  const authorId = useAuthorId();
  const author = useAuthor(authorId)
  const { state, dispatch } = useContext(StateContext);

  useEffect(() => {
    dispatch({ type: ActionType.HideLoading });
  }, [])


  return <>
    <SEO title="Portfolio" image={author.avatar.publicURL} />
    <motion.div transition={{ type: 'spring', stiffness: 700, damping: 100 }}
      variants={{
        off: { opacity: 0, y: 40 },
        on: { opacity: 1, y: 0 },
      }}
      initial={"off"}
      animate={state.isLoading ? "off" : "on"}>

      <Home author={author} />
    </motion.div>
  </>
}

export default IndexPage
