import { Box, Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import React, {  } from 'react'
import ReactMarkdown from 'react-markdown';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import LatoBoldText from '../../LatoBoldText';
interface Props {
    author: any;
}

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        background: 'white',
        boxShadow: '0 3px 8px rgba(0,0,0,0.08)',
        borderRadius: '12px',
        position: 'relative',
        '&:after': {
            content: '""',
            position: 'absolute',
            width: 0,
            height: 0,
            marginLeft: '-0.5em',
            top: '0',
            left: '93px',
            boxSizing: 'border-box',

            border: '1em solid black',
            borderColor: 'transparent transparent white white',

            transformOrigin: '0 0',
            transform: 'rotate(135deg)',

            boxShadow: '-3px 3px 3px 0px rgba(0,0,0,0.02)',
        }
    },
    markDown: {
        '&>h1': {
            fontFamily: theme.typography.h1.fontFamily,
            color: theme.typography.h1.color
        },
        '&>h1:first-of-type': {
            marginTop: 0,
        },
        '&>p': {
            letterSpacing: '0.5pt',
            lineHeight: '21pt',
            fontSize: '1.1rem',
        }
    },
    dot: {
        height: '5px',
        width: '5px',
        backgroundColor: '#f8a255',
        borderRadius: '50%',
        marginLeft: theme.spacing(1),
        marginTop: '8px',
        marginBottom: '8px',
        display: 'block',
        [theme.breakpoints.up('sm')]: {
            marginTop: '0',
            marginBottom: '6px',
            display: 'inline-block',
        },
    },
    linkedin: {
        marginBottom: '-3px',
        marginLeft: 0,
        color: theme.palette.text.secondary,
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(1)
        }
    },
    mailAddress: {
        textDecoration: 'none',
        color: 'inherit'
    }
}));

const AuthorDescriptionCard = (props: Props) => {
    const classes = useStyles();
    const { author } = props;

    return (
        <Box padding={6} className={classes.root}>
            <ReactMarkdown source={author.description} className={classes.markDown} />
            <LatoBoldText display="inline"><a className={classes.mailAddress} href={`mailto:${author.email}`}>{author.email}</a></LatoBoldText>
            <span className={classes.dot}></span>
            <a target="_blank" href={author.linkedin}><LinkedInIcon className={classes.linkedin} /></a>
        </Box>
    )
}

export default AuthorDescriptionCard
