import { Grid, makeStyles, Theme } from '@material-ui/core'
import React from 'react'
import AuthorContainer from './AuthorContainer'
import AuthorDescriptionCard from './AuthorDescriptionCard';

const useStyles = makeStyles((theme: Theme) => ({
    gridContainer: {
      display: "flex",
      minHeight: "100vh",
      alignItems: 'center',
      justifyContent: 'center',
      margin: '0 0 48px 0',
      '@media (min-width: 600px)': {
        margin: '0'
      },
    },
    authorContainer: {
      margin: "48px 48px 48px 48px",
      '@media (min-width: 600px)': {
        margin: "0 48px 48px 48px",
      }
    },
    itemContainer: {
      margin: "8px",
      '@media (min-width: 1400px)': {
        flexGrow: 0,
        maxWidth: '50%',
        flexBasis: '50%',
      }
    }
  }));

interface Props {
    author: any
}

const Home = ({author}: Props) => {
    const classes = useStyles();
    
    return <Grid container className={classes.gridContainer}>
        <Grid item xs={12} sm={10} md={8} lg={8} className={classes.itemContainer}>
            <AuthorContainer author={author} className={classes.authorContainer} />
            <AuthorDescriptionCard author={author} />
        </Grid>
    </Grid>
}

export default Home
