import { makeStyles, Theme, Typography, TypographyProps } from '@material-ui/core';
import clsx from 'clsx';
import React, { ReactElement } from 'react'

interface LatoBoldTextProps extends TypographyProps {

}

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        letterSpacing: "1.14pt",
        fontWeight: "bold",
        color: "#5F2756",
        fontSize: '1.4rem',
    }
}));

function LatoBoldText(props: LatoBoldTextProps): ReactElement {
    const classes = useStyles();

    return (
        <Typography {...props} className={clsx(classes.root, props.className)}>
            {props.children}
        </Typography>
    )
}

export default LatoBoldText;
