import React from 'react';
import { Box, Theme, Typography } from '@material-ui/core';
import FlexBox from '../../FlexBox';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import { BoxProps } from '@material-ui/core/Box/Box';
import LatoBoldText from '../../LatoBoldText';

interface AuthorContainerProps extends BoxProps {
  author: any
}

const useStyles = makeStyles(() => ({
  avatar: {
    width: '13.5rem',
    marginBottom: '24px',
    '@media (min-width: 600px)': {
      width: '11rem',
      marginBottom: '0',
    },
    '@media (min-width: 700px)': {
      width: '13rem',
    },
    '@media (min-width: 900px)': {
      width: 'auto',
    }
  },
  title: {
    letterSpacing: "1pt",
  },
  textContainer: {
    width: '100%',
    '@media (min-width: 600px)': {
      width: 'auto',
      marginLeft: '3rem',
    }
  },
  container: {
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'wrap',
    textAlign: 'center',
    '@media (min-width: 600px)': {
      flexWrap: 'nowrap',
      textAlign: 'inherit',
    }
  }
}));

export const AuthorContainer = (props: AuthorContainerProps) => {
  const classes = useStyles();
  const { author } = props;
  return <FlexBox {...props} className={clsx(classes.container, props.className)}>
    <img src={author.avatar.publicURL} className={classes.avatar} />
    <Box className={classes.textContainer}>
      <Typography variant={"h2"} className={classes.title}>{author.firstName}</Typography>
      <Typography variant={"h2"} className={classes.title}>{author.lastName}</Typography>
      <LatoBoldText>{author.jobTitle}</LatoBoldText>
    </Box>
  </FlexBox>;
};

export default AuthorContainer;
